// Full-page styling
.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 

  .success-message {
    text-align: center !important;
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 1s forwards;

    .icon {
      color: #092475 !important; // Success green color for icon
      font-size: 4rem;
      margin-bottom: 20px;
      animation: icon-bounce 0.6s ease-in-out;
    }

    .animated-title {
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: 20px;
      color: #333;
      opacity: 0;
      animation: fadeIn 1.2s 0.2s forwards;
    }

    .animated-text {
      font-size: 1.5rem;
      color: #555;
      margin-bottom: 20px;
      line-height: 1.6;
      opacity: 0;
      animation: fadeIn 1.2s 0.4s forwards;
      text-align: center !important;
    }

    .animated-contact {
      opacity: 0;
      animation: fadeIn 1.2s 0.6s forwards;

      p {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #333;
        text-align: center !important;
        strong {
          color:#092475 !important; // Bootstrap blue for emphasis
        }
      }
    }
  }
}

// Keyframe animations
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes icon-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .success-message {
    padding: 20px;
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }

    .contact-details p {
      font-size: 1rem;
    }
  }
}
