.program-title-box {
    display: inline-block; /* Makes the box fit the content */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    padding: 8px 15px; /* Adjusted padding for a smaller box */
    border-radius: 6px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-top: 10px; /* Space above the box */
}

.program-details-title {
    margin: 0; /* Removes default margin from h3 */
    font-size: 10px ; /* Adjusted font size for smaller text */
    color: #092475 !important; /* Color for better visibility */
}
 