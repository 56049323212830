.terms-container {
    background-color: #17307c; // Dark background color for the container
    padding: 20px; // Added padding for better spacing
    
    h1 {
      color: white; // Main heading color
      text-align: center;
      margin-bottom: 20px;
      font-size: 2.5rem; // Main heading size
      
    }
  
    h2 {
      color: white; // Darker color for section headings
      font-size: 1.5rem; // Subsection heading size
      font-weight: 600;
      margin-top: 30px; // Spacing above subsections
    }
  
    p {
      font-size: 1rem; // Standard text size for paragraphs
      line-height: 1.6;
      color: white;
      margin-bottom: 15px;
      font-weight: 500;
    }
  
    // Signature/last paragraph style
    p:last-of-type {
      font-style: italic;
      text-align: center;
      margin-top: 30px;
    }
  }
  