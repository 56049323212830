.studentForm {
    max-width: 800px; // Set a max width for the form
    margin: 0 auto;   // Center the form on the page
    padding: 20px;    // Add padding for inner spacing
    border: 1px solid #ccc; // Light border for the form
    border-radius: 8px; // Rounded corners
    background-color: #f9f9f9; // Light background color
  
    h2 {
      font-size: 1.8rem; // Size for section headings
      margin-bottom: 20px; // Space below the heading
      color: #333; // Heading color
    }
  
    label {
      display: block; // Make labels block elements
      margin-bottom: 5px; // Space between label and input
      font-weight: bold; // Bold labels
      color: #555; // Label color
    }
  
    input[type="text"],
    input[type="date"],
    select,
    textarea {
      width: 100%; // Full width inputs
      padding: 10px; // Padding inside inputs
      margin-bottom: 15px; // Space below inputs
      border: 1px solid #ccc; // Border for inputs
      border-radius: 4px; // Rounded corners for inputs
      font-size: 1rem; // Font size for inputs
      transition: border-color 0.3s ease; // Smooth transition for border color
  
      &:focus {
        border-color: #007bff; // Change border color on focus
        outline: none; // Remove outline
      }
    }
  
    textarea {
      height: 100px; // Set a height for text area
      resize: none; // Prevent resizing
    }
  
    select {
      appearance: none; // Remove default styling
      background-color: white; // White background for select
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M5 8l5 5 5-5H5z" fill="%23007bff"/></svg>'); // Custom dropdown icon
      background-repeat: no-repeat; // Don't repeat background image
      background-position: right 10px center; // Position icon
      background-size: 12px; // Size of the icon
    }
  
    button {
      background-color: #007bff; // Button color
      color: white; // Text color for button
      padding: 10px 15px; // Padding for button
      border: none; // Remove border
      border-radius: 4px; // Rounded corners for button
      cursor: pointer; // Pointer cursor for button
      font-size: 1rem; // Font size for button
      transition: background-color 0.3s ease; // Transition for background color
  
      &:hover {
        background-color: #0056b3; // Darker color on hover
      }
    }
  
    // Responsive styles
    @media (max-width: 768px) {
      padding: 15px; // Reduce padding on smaller screens
  
      h2 {
        font-size: 1.5rem; // Adjust heading size
      }
  
      input[type="text"],
      input[type="date"],
      select,
      textarea {
        padding: 8px; // Reduce padding in inputs
      }
  
      button {
        width: 100%; // Full width button on small screens
      }
    }
  }
  