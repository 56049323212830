// SignupModal.scss

.input-icon {
  font-size: 1.25rem; // Adjust icon size
  color: #495057; // Icon color
}

.form-control-lg {
  padding-left: 2.5rem; // Space for the icon
  font-size: 1.25rem; // Adjust font size if needed
  border-radius: 0.25rem; // Border radius
}

.border-blue {
  border-color: #092475; // Border color
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); // Shadow effect
}

.position-relative {
  position: relative; // Positioning context for the icon
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
 
}

.signup-modal .modal-body {
  padding: 3rem; // Adjust padding for larger card size
}
.modal-header {
  border: none;

  background-color: #f8f9fa;
}
.modal-image {
  width: 100%; /* Ensure it takes full width */
  height: 100%; /* Ensure it takes the full height of the column */
  object-fit: cover; /* Ensures the image maintains aspect ratio */
}

.btn-close {
  background-color: transparent; /* Ensure it blends well with the background */
  border: none; /* Remove default border */
  font-size: 1.5rem; /* Increase the size */
  outline: none;
  color: #092475;
}
