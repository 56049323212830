.video-area {
  position: relative;
  overflow: hidden;
  height: 100vh; // Full viewport height

  .video-wrapper {
    position: relative;
    height: 100%;
  }

  .video-background {
    width: 100%;
    height: 100%;
    object-fit: cover; // Ensure the video covers the area
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); // Semi-transparent overlay
    display: flex;
    justify-content: center;
    align-items: center;

    .video-content {
      text-align: center;
      color: #fff;
      z-index: 1; // Ensure content is above the overlay

      h1 {
        font-size: 3rem; // Increased heading font size
        color: #fff;
        font-weight: bold;
        line-height: 1.2; // Improved line spacing
      }

      p {
        font-size: 1.2rem; // Increased paragraph font size
        line-height: 1.6; // Better readability for the paragraph
        color: white;
        margin-bottom: 30px;
      }
      .button {
        display: inline-block;
        height: 54px;
        line-height: 48px;
        border: 2px solid white; // Default border color
        background: #092475; // Default background color
        color: #ffffff; // Text color
        padding: 0 30px;
        border-radius: 10px;
        font-weight: 700;
        text-transform: capitalize;
        min-width: 200px; // Set the button width
        text-align: center;
        text-decoration: none; // Remove underline
        transition: background 0.3s, color 0.3s, border-color 0.3s; // Smooth transitions
        position: relative;
        margin-top: 20px; // Space above the button

        &:hover {
          background: #ffffff; // White background on hover
          color: #092475; // Text color on hover
          border-color: #092475; // Border color on hover
          cursor: pointer;
        }
      }
   
    }
  }
}

.video-content {
  text-align: center;
  color: white;
  padding: 20px;
}

.video-content p {
  font-size: 1.2rem; // Adjusted size for better readability
  color: white;
  margin-bottom: 30px;
}
