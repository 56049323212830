.list-item-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100px;
    width: 85%;
    padding: 25px;
    box-sizing: border-box;
    min-height: 50px;
    overflow: hidden; 
    align-items: center;
    // box-shadow: ;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.list-item-card:hover{
    box-shadow: rgba(99, 84, 173, 0.25) 0px 54px 55px, rgba(84, 106, 216, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: 0.5s;
    cursor: pointer;
    scale: 1.01;
}
.list-item-name{
    font-family: roboto-serif;
    font-size: 20px;
}
.count{
    font-family: roboto-serif;
    font-size: 25px;
    text-align: end;

}


.list-item-right{
    display: flex;
    flex-direction: column;
    gap: 25px;
    // justify-content: space-between;
}
// @media screen and (max-width: 1100px) {
//     .list-item-right{
//         display: flex;
//         flex-direction: column;
//         gap: 25px;
//     }
//     .list-item-name{
//         display: none;
//     }
// }