/*----------------------------------------*/
/*  13. ACHIEVEMENT CSS START
/*----------------------------------------*/

.achievement__area {
  .container {
    .row {
      .achievement__content {
        text-align: center; // Center all text within this section

        h2 {
          color: $theme-color;
        }
        p {
          margin: 0 auto; // Centers the paragraph
          max-width: 600px; // Optional: set a max-width for better readability
        }

        .z-btn {
          border-radius: 18px;
background-color:#092475;
          color: white;
          border: none;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer;
          transition: background 0.3s ease;
          display: inline-block;
          margin: 20px auto; // Centers the button horizontally and adds some spacing above and below
        }

        .z-btn:hover {
          background-image: -moz-linear-gradient(
            60deg,
            #c24e4e 0%,
            #2528cc 100%
          );
          background-image: -ms-linear-gradient(
            60deg,
            #c24e4e 0%,
            #2528cc 100%
          );
          background-image: -webkit-linear-gradient(
            60deg,
            #c24e4e 0%,
            #2528cc 100%
          );
          background-image: linear-gradient(60deg, #c24e4e 0%, #2528cc 100%);
        }
      }
    }
  }
}
