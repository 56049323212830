@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');
.sidebar-open {
    margin-left: 250px; 
    transition: all 0.3s ease-in;
  }
  
  .sidebar-closed {
    margin-left: 78px;
    // transition: all 1s ease;
  }
  .animate__animated.animate__bounce {
    --animate-duration: 2s;
  }
  .middle-container{
    margin-top: 70px !important;
  }
.top-nav{
  text-align: right;
  margin-right: 5%;
}
@media screen and (max-width: 1550px) {
  .top-nav{
    text-align: right;
    margin-right: 8%;
  }
  
}
@media screen and (max-width: 1200px) {
  .top-nav{
    text-align: right;
    margin-right: 15%;
  }
  
}
@media screen and (max-width: 992px) {
  .top-nav{
    text-align: right;
    margin-right: 20%;
  }
  
}
.main-container{
  width: 100vw;
}