.payments-container {
  padding: 20px;
  font-family: 'Arial', sans-serif;

  h1 {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    font-size: 24px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    th, td {
      text-align: left;
      padding: 10px;
      border: 1px solid #ddd;
      font-size: 14px;
    }

    th {
      background-color: #007bff;
      color: white;
      font-weight: bold;
    }

    tbody tr {
      &:hover {
        background-color: #f9f9f9;
        transition: background-color 0.3s;
      }
    }

    .view-icon {
      font-size: 16px;
      cursor: pointer;
      color: #007bff;
      text-align: center;

      &:hover {
        color: #0056b3;
        transform: scale(1.1);
        transition: color 0.3s, transform 0.2s;
      }
    }
  }

  // Responsive Design
  @media (max-width: 768px) {
    table {
      font-size: 12px;

      th, td {
        padding: 8px;
      }
    }

    h1 {
      font-size: 20px;
    }
  }
  
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; /* Centers content vertically */
  z-index: 1000;
}

.modal {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 50%; /* Prevents the modal from overflowing the viewport */

}

.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.modal-heading {
  text-align: center;
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
}

.modal-body {

  font-size: 16px;
  color: black;
  margin-bottom: 0;
}
}
