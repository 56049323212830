#backtotop {
    opacity: 0;
    right: 20px;
    z-index: 99;
    width: 50px;
    bottom: 20px;
    height: 50px;
    font-size: 25px;
    line-height: 49px;
    background-color: blue;
    transition: 0.5s;
    -webkit-animation: border-transform 10s linear infinite alternate forwards;
    animation: border-transform 10s linear infinite alternate forwards;
    color: var(--whiteColor);
  }
  
  #backtotop:hover {
    background-color: red;
  }
  