/*----------------------------------------*/
/*  21. PORTFOLIO CSS START
/*----------------------------------------*/

.portfolio{
    &__area{
        
    }
    &__item{
        &:hover{
            & .portfolio__thumb{
                &::before{
                    opacity: .9;
                }
            }
            & .portfolio__plus{
                visibility: visible;
                opacity: 1;
                top: 50%;
            }
            & .portfolio__more{
                visibility: visible;
                opacity: 1;
                left: 0;
            }
        }
    }
    &__thumb{
        position: relative;
        & img{
            @include border-radius(10px);
        }
        &::before{
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: -moz-linear-gradient( 60deg, #c24e4e 0%, #2528cc 100%);
            background-image: -ms-linear-gradient( 60deg,#c24e4e 0%, #2528cc  100%);
            background-image: -webkit-linear-gradient( 60deg,#c24e4e 0%, #2528cc 100%);
            background-image: linear-gradient( 60deg, #c24e4e 0%, #2528cc 100%);
            @include border-radius(10px);
            opacity: 0;
        }
    }
    &__content{
        padding: 35px 0;
        & h4{
            font-size: 24px;
            margin-bottom: 5px;
            & a{
                &:hover{
                    color: $theme-color;
                }
            }
        }
        & p{
            margin-bottom: 0;
        }
    }
    &__plus{
        top: 60%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        & button{
            display: inline-block;
            width: 42px;
            height: 42px;
            line-height: 38px;
            text-align: center;
            border: 2px solid rgba($color: $white, $alpha: .4);
            color: $white;
            font-size: 20px;
            // border: none;
            background: none;
            @include border-radius(50%);
            position: relative;
            overflow: hidden;
            &:hover{
                color: $black;
                border-color: $white;
                background: $white
            }
            &:hover{
                & i:first-child{
                    left: -120%;
                }
                & i:last-child{
                    left: 50%;
                }
            }
            & i{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -2px;
                @include transform(translate(-50%, -50%));
                @include transition(.2s);
                &:last-child{
                    left: 120%;
                }
            }
        }
    }
    &__more{
        bottom: 30px;
        background: $white;
        z-index: 1;
        padding: 3px 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        left: -30px;
        & .link-btn{
            font-size: 16px;
            &:hover{
                color: $black;
            }
        }
    }
    &__load{
        & a{
            & i{
                margin-left: 0;
                padding-right: 5px;
            }
        }
    }
    &__menu{
        & span{
            color: $black;
        }
        & button{
            color: $black-soft-4;
            padding: 0 20px;
            background: transparent;
            @media #{$sm}{
                padding: 0 10px;
            }
            @media #{$xs}{
                display: block;
                padding: 10px 0px;
            }
            &.active{
                color: $theme-color;
            }
            &:hover{
                color: $theme-color;
            }
        }
    }
    &__details{
        &-content{
            & h1{
                font-size: 50px;
                margin-bottom: 30px;
                @media #{$xs}{
                    font-size: 40px;
                }
            }
        }
    }
    &__meta{
        & h4{
            display: inline-block;
            font-family: $lato;
            font-weight: 700;
            font-size: 16px;
        }
        & span{

        }
    }
    &__info{
        & h3{
            font-family: $lato;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 7px;
        }
    }
    &__overview{
        & h2{
            font-size: 20px;
        }
        & p{
            font-size: 18px;
            line-height: 30px;
        }
    }
    &__pagination{
        &-wrapper{
            padding-top: 30px;
            border-top: 1px solid $border-9;
        }
    }
}

.portfolio-section {
    padding: 40px 0;
    
    .portfolio-intro {
      text-align: center;
      margin-bottom: 40px;
  
      h2 {
        font-size: 2rem;
        margin-bottom: 20px;
        color: #333;
      }
  
      p {
        font-size: 1rem;
        color: #666;
        max-width: 800px;
        margin: 0 auto;
      }
    }
  
    .portfolio-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
  
  .portfolio__item {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    
  
    .portfolio__thumb {
      position: relative;
      overflow: hidden;
  
      img {
        width: 100%;
        height: 50%;
        display: block;
      }
  
      .portfolio__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba(0, 0, 0, 0.7);
        color: #fff;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        transition: opacity 0.3s ease;
  
        h3 {
          margin: 0;
          a {
            color: #fff;
            text-decoration: none;
            font-size: 2.5rem;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      &:hover .portfolio__overlay {
        opacity: 1;
      }
    }
  
    .portfolio__info {
      padding: 20px;
      text-align: center;
  
      p {
        margin-bottom: 20px;
        font-size: 1rem;
        color: #666;
      }
  
      .link-btn-2 {
        background: #007bff;
        color: #fff;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;
        transition: background 0.3s ease;
        &:hover {
          background: #0056b3;
        }
      }
    }
  }

  
  .portfolio__area {
    .portfolio__menu {
        text-align: center;

        .portfolio__subtitle {
        
            font-size: 1.2rem; // Adjust size as needed
            font-weight: bold;
            color: #ff36a8; // Customize this color to fit your design
    
        }

        .portfolio__title {
            font-size: 4.5rem; // Adjust size as needed
            font-weight: bold;
            color: #333; // Adjust color to fit your theme
            margin-bottom: 20px;
        }

        .portfolio__description {
            font-size: 1.195rem; // Adjust size as needed
            color: #3a3939; // Customize to fit your design
        
            margin: 0 auto; // Center the text
        }
    }
  
}

  



.portfolio__area {
    padding-left: 40px; /* Adjust as needed */
  }
  
  .founder-info {
    display: flex;
    align-items: flex-start; /* Align the image and paragraph at the top */
    gap: 30px; /* Increase the space between the image and text */
  }
  
  .founder-info img {
    width: 255px; /* Increase the image size */
    height: auto; /* Maintain the aspect ratio */
  }
  
  .founder-info p {
    flex: 1; /* Allow the paragraph to take up the remaining space */
    
    text-align: justify; /* Align the text to both the left and right edges */
    line-height: 1.9; /* Adjust line spacing */
  }

  /* For small screens (max-width: 768px) */
@media screen and (max-width: 768px) {
    .founder-info {
      flex-direction: column; /* Stack image and text vertically */
      align-items: center; /* Center align the items */
      padding-left: 0; /* Remove left padding */
    }
  
    .founder-info img {
      width: 100%; /* Make image take up full width */
      max-width: 300px; /* Set a max-width for the image */
    }
  
    .founder-info p {
      padding-top: 20px; /* Add some space above the text */
      text-align: center; /* Center-align the text for smaller screens */
    }
  }
  