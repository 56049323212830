/* General styling for the sidebar */
.course__sidebar {
  font-size: 1rem; /* Adjust to medium size */
  padding: 20px;
  background-color: #f8f9fa; /* Light background for better visibility */

 width: 250px;
}
.small-btn {
  padding: 0 !important;
  font-size: 14px !important;

}
/* FAQ Section */
.sidebar__faq {
  .widget {
    margin-bottom: 30px;
    
    .widget__title {
      font-size: 1.25rem; /* Medium-sized title */
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem; /* Medium-sized paragraph */
      line-height: 1.5;
    }

    .z-btn {
      font-size: 0.875rem; /* Medium-sized button */
      padding: 8px 16px;
    }

    .sidebar__image {
      margin-top: 15px;
      width: 100%;
      border-radius: 5px;
    }
  }

  .faq__accordion {
    .accordion-item {
      margin-bottom: 15px;

      .accordion-header {
        .btn {
          font-size: 1rem; /* Medium-sized button text */
          color: #333;
          font-weight: 500;
        }
      }

      .accordion-body {
        font-size: 1rem; /* Medium-sized body text */
        padding: 15px;
      }

      .accordion-body p {
        margin-bottom: 0;
      }
    }
  }
}

/* Medium-sized layout for small screens and larger */
@media (min-width: 576px) {
  .course__sidebar {
    font-size: 1.125rem; /* Slightly larger for medium devices */
  }

  .sidebar__faq {
    .widget {
      .widget__title {
        font-size: 1.375rem; /* Adjusted title size */
      }

      p, .accordion-body p {
        font-size: 1.125rem;
      }
    }
  }
}
