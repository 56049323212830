.blog-feature-image {
    height: 230px !important;
    object-fit: cover;
    overflow: hidden;
    background-size: cover;
    width: 100%;
    transition: transform 0.5s ease; 
}

.img-hover-zoom--blur img {
    transition: transform 1s, filter 2s ease-in-out;
    filter: blur(2px);
    // transform: scale(1.2);
  }
  
  .img-hover-zoom--blur:hover img {
    filter: blur(0);
    transform: scale(1.02);
  }

  .set{
    overflow: hidden;
  }