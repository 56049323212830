.cookie-consent {
    position: fixed;
    bottom: 10px;
    right: -370px;
    max-width: 420px; /* Slightly reduced width */
    width: 100%;
    background: #092475; /* Changed background to blue */
    border-radius: 8px;
    padding: 15px 20px 22px; /* Adjusted padding for a more compact layout */
    transition: right 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999; /* Stays on top of other content */
  

    &.show {
        right: 20px;
    }

    &__header {
        display: flex;
        align-items: center;
        column-gap:5px; /* Reduced gap between icon and text */
       
        &-icon {
            color: #fff; /* Icon color changed to white */
            font-size: 36px; /* Increased icon size */
           
        }

        &-title {
            color: #fff; /* Title color changed to white */
            font-weight: 500;
        }
    }

    &__data {
        margin-top: 16px;

        p {
            color: #fff; /* Paragraph text changed to white */
            font-size: 15px; /* Slightly smaller font for compactness */
            
            a {
                color: #fff; /* Link text color changed to white */
                text-decoration: underline; /* Keep the underline to distinguish links */

                &:hover {
                    text-decoration: none; /* Hover effect removes underline */
                }
            }
        }
    }

    &__buttons {
        margin-top: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-button {
            border: 2px solid #fff; /* Border is white */
            background: #fff; /* Button background white */
            color: #092475; /* Button text is blue */
            padding: 7px 0; /* Adjusted padding for buttons */
            border-radius: 4px;
            cursor: pointer;
            width: calc(100% / 2 - 8px); /* Slightly smaller button widths */
            transition: all 0.2s ease;

            &--accept:hover {
                background-color: #092475; /* Blue background on hover */
                color: #fff; /* White text on hover */
            }
        }

        &-decline {
            border: 2px solid #fff;
            background-color: #fff;
            color: #092475; /* Blue text */
            padding: 7px 0; /* Adjusted padding for buttons */
            width: calc(100% / 2 - 8px);

            &:hover {
                background-color: #092475; /* Blue background on hover */
                color: #fff; /* White text on hover */
            }
        }
    }
}
