// CourseTable.scss
.programs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    margin: 0;
    font-size: 24px;
  }

  .add-program-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.course-table {
  h1 {
    background-color: #092475; // Heading background color
    color: white; // Heading text color
    padding: 15px; // Padding for the heading
    border-radius: 5px; // Rounded corners
    text-align: center; // Centered heading text
    margin-bottom: 20px; // Margin below the heading
  }

  table {
    width: 100%; // Full-width table
    border-collapse: collapse; // Collapse borders
    margin-top: 20px; // Margin above the table
    border: 1px solid white; // White border for the table
    text-align: justify;

    thead {
      background-color: #092475; // Header background color
      color: white; // Header text color

      th {
        padding: 12px; // Padding for header cells
        text-align: left; // Align text to the left
      }
    }
    .icons {
      display: flex;
      justify-content: center;
    }
    tbody {
      tr {
        background-color: #f1f1f1; // Background color for table rows
        transition: background-color 0.3s; // Smooth transition on hover

        &:hover {
          background-color: #e0e0e0; // Lighter gray on hover
        }

     

        td {
          padding: 10px; // Padding for table data cells
          border: 1px solid white; // White border between cells

          button {
            display: flex; // Flexbox for button content
            align-items: center; // Center items vertically
            padding: 6px 12px; // Padding for buttons
            background-color: #007bff; // Button color
            color: white; // Button text color
            border: none; // No border for buttons
            border-radius: 4px; // Rounded corners
            cursor: pointer; // Pointer cursor
            transition: background-color 0.3s; // Smooth transition
            // Icon styles
            svg {
              margin-right: 5px; // Space between icon and text
            }

            &:hover {
              background-color: #0056b3; // Darker shade on hover
            }
          }
        }
      }
    }
  }

  // Modal styling
  // Modal styling
  .modal {
    position: fixed;
    top: 10%; // Lower the modal slightly from the top
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); // Semi-transparent background
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000; // Ensure modal is on top of other elements

    .modal-content {
      background: white;
      padding: 30px;
      border-radius: 8px;
      width: 900px; // Wider modal
      max-width: 95%; // Ensure it doesn't overflow smaller screens
      min-height: 500px;
      position: relative; // Ensure elements inside are positioned correctly

      h2 {
        margin-top: 0;
      }

      // Add a close button
      .close-btn {
        position: absolute;
        top: 10px; // Positioned at the top-right corner
        right: 15px;
        background-color: transparent;
        border: none;
        font-size: 18px;
        cursor: pointer;
        color: #ff4757;

        &:hover {
          color: #ff6b81;
        }
      }

      .mbtn {
        display: inline-block; // Ensure the button is displayed
        margin-top: 15px;
        padding: 10px 20px; // Increased padding for the button
        color: white; // White text for contrast
        background-color: #ff4757; // Button background
        border: none; // Remove any default borders
        border-radius: 4px; // Slightly rounded corners
        cursor: pointer; // Ensure the button is clickable
        z-index: 1001; // Ensure button is above other elements

        &:hover {
          background-color: #ff6b81; // Darker shade on hover
        }
      }
    }
  }


}