.set-password-area {
  .card {
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .form-outline {
    position: relative;
    margin-bottom: 1.5rem;

    // Add focus/active style for the label
    .form-control {
      border-radius: 0.5rem;
      padding-right: 3rem; // Space for the icon
      font-size: 1rem;
      padding: 0.75rem;
      border: 1px solid #ced4da;
      background-color: #fff;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;

      &:focus {
        border-color: #092475;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      &:focus ~ .form-label,
      &:not(:placeholder-shown) ~ .form-label {
        transform: translateY(-1.5rem);
        font-size: 0.75rem;
        color: #092475;
      }
    }

    .password-input {
      padding-right: 2.5rem; // Adjusted to fit the icon inside
    }

    .form-label {
      position: absolute;
      top: 0.75rem;
      left: 0.75rem;
      font-size: 1rem;
      color: #666;
      pointer-events: none;
      transition: all 0.3s ease;
      background-color: #fff;
      padding: 0 0.25rem;
    }

    // Styling for password toggle icon
    .password-toggle {
      position: absolute;
      top: 50%;
      right: 0.95rem;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 1.25rem;
      color: #092475;
      z-index: 1;
    }
  }

  .password-requirements {
    margin-bottom: 1.5rem;
    
    p {
      font-weight: bold;
    }

    ul {
      list-style-type: disc;
      margin-left: 1.5rem;
    }
  }

  .error-message {
    color: #ff0000;
    margin-bottom: 1.5rem;
  }
}
