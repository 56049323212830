.admin-layout {
  
    height: 100vh; /* Full height of the viewport */
  }
  
  .admin-body {
    display: flex;
    flex: 1; /* Allows body to take up all available space */
  }
  
  .admin-content {
    flex: 1; /* Take up remaining width */
    padding: 20px;
    background-color: #f5f5f5;
    overflow-y: auto; /* Scrollable content if needed */
    margin-left: 50px;
  }
  
  .AdminSideNav {
    width: 200px; /* Make the sidebar narrower */
    background-color: #2c3e50;
    height: 100vh; /* Full height sidebar */
  }
  
  .AdminNavBar {
    height: 60px; /* Adjust height as needed */
    background-color: #34495e;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  