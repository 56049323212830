.forgot-password-area {
    
  
    .form-group {
      margin-bottom: 1.5rem;
    }
  
    .form-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
   
    .password-toggle {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  
    .password-requirements {
      font-size: 0.85rem;
      color: #6c757d;
  
      ul {
        padding-left: 1rem;
      }
  
      li {
        margin-bottom: 0.5rem;
      }
   
    }}  