.program-form {
  margin:30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;


  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .form-group {
      display: flex;
      flex-direction: column;
      flex: 1 1 calc(33.33% - 20px); /* Adjust width for three fields */
      min-width: 200px; /* Ensures a minimum size for smaller screens */

      label {
        margin-bottom: 5px;
        font-size: 20px;
        color: black;
        text-decoration: solid;
      }

      input[type="text"],
      input[type="number"],
      input[type="file"],
      select {
        padding: 10px;
        border: 2px solid #ccc;
        width: 80%;
        border-radius: 4px;
        font-size: 14px;
        outline: none;
        transition: border-color 0.3s;

        &:focus {
          border-color: #007bff;
        }
      }

      select {
        appearance: none;
        background: #fff;
      }
    }

    .submit-btn {
      width: 20%;
      padding: 10px 15px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
      display: block; /* Ensures the button takes up a block-level space */
  margin: 20px auto; 

      &:hover {
        background-color: #0056b3;
      }
    }
  }
  .modal {
    position: fixed;
   
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
   
  
    &.visible {
      display: flex;
      border:2px solid
    }
  
    .modal-content {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      width: 1000px;
      height:550px;
    
      .close {
        float: right;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
  
        &:hover {
          color: #f00;
        }
      }
  
      h3 {
        margin-bottom: 20px;
        font-size: 18px;
        color: #333;
      
      }
  
      .submit-btn {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
      }
    }
  }
  
  @media (max-width: 768px) {
    form {
      .form-group {
        flex: 1 1 100%;
      }
    }
  
    .modal-content {
      width: 90%;
    }
  }
  
  @media (max-width: 576px) {
    form {
      gap: 10px;
    }
  }
  
  // Keeping existing hover effect for buttons
  .add-programs-container .add-programs-form .btn:hover {
    color: #092475 !important;
  }
  
  .dropdown-menu.show {
    width: 90%;
    height: 400px;
    overflow-y: scroll;
   }
  
  /* Custom ReactQuill Editor Styling */
  .custom-react-quill {
    .ql-container {
      height: 300px; /* Sets height of the editor */
      font-size: 16px; /* Adjusts font size for readability */
      border: 1px solid #ced4da;
      border-radius: 5px;
      padding: 10px;
      background-color: #ffffff;
    
      
  
      .ql-editor {
        min-height: 200px; /* Ensures the editor has a minimum height */
      }
  
      .ql-toolbar {
        background-color: #f1f3f5;
        border-bottom: 1px solid #ced4da;
      }
    }
  
    /* Focus state for better UX */
    .ql-container.ql-snow.ql-focused {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }}
  
    .btns{
      padding:10px;
  font-weight: 500;
  border:1px solid #092475 ;
  width: 300px;
    }
  
}
