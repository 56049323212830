/* Profile icon - make it larger */
.header__profile i {
  font-size: 24px; /* Adjust this value as needed */
  color: #000; /* You can customize the color if needed */
}

.header__logout .z-btn {
 
  font-size: 12px;  /* Smaller font size */
  background-color: #fff; /* Keep the background white */
  color: #092475 !important; /* Black text */
  border: 2px solid #092475 !important; /* Thin border for contrast */
  border-radius: 23px; /* Slightly rounded corners */
  width: auto; /* Ensure the button size is adjusted dynamically */
  height: auto; /* Remove fixed height */
  
}



/* Optional: Hover effect for the logout button */
.header__logout .z-btn:hover {
  background-color: #092475 !important; /* Change background on hover */
  color: #fff !important; /* White text on hover */
  transition: all 0.3s ease; /* Smooth transition */
}
@media (max-width: 768px) {
  .header__bottom-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    
    .header__profile {
      margin-left: 0;
      margin-right: 5px; /* Reduced space between profile icon and logout button */
      
      i {
        font-size: 16px; /* Smaller profile icon */
      }
    }

    .header__logout {
      button {
        font-size: 10px; /* Smaller font size */
        padding: 4px 8px; /* Smaller padding for the button */
        margin-right: 5px; /* Reduced space to the sidebar toggle */
        border-radius: 4px; /* Optional: add slight border radius */
      }
    }

    .sidebar__menu {
      display: block;
      cursor: pointer;

      .sidebar-toggle-btn {
        .line {
          height: 3px; /* Smaller height for the lines */
          width: 20px; /* Smaller width for the lines */
          background-color: #000; /* Ensure visibility */
          margin: 2px 0; /* Adjust margin between lines */
        }
      }
    }
  }

  .header__bottom {
    padding: 5px 0; /* Adjust padding on mobile for better spacing */
  }
}
.logo img {
  max-width: 150px; // Default size for larger screens

  @media (max-width: 991px) {
    max-width: 100px; // Smaller logo for mobile screens
  }

  @media (max-width: 576px) {
    max-width: 150px; // Even smaller for extra small devices
  }
}
.logo-gradient img {
  max-width: 150px; // Default size for larger screens

  @media (max-width: 991px) {
    max-width: 100px; // Smaller logo for mobile screens
  }

  @media (max-width: 576px) {
    max-width: 150px; // Even smaller for extra small devices
  }
}