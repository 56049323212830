
  .login-container {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 50px;
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .login-title {
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 2em;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    color: #fff;
    font-size: 1.2em;
    margin-bottom: 8px;
    display: block;
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    font-size: 1.1em;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  
  .input-group input::placeholder {
    color: #ddd;
  }
  
  .input-group input:focus {
    border: 1px solid #092475;
    outline: none;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    font-size: 1.2em;
    border-radius: 5px;
    background-color: #092475;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .login-button:hover {
    background-color: #092475;
  }
  
  .login-button:disabled {
    background-color: #9c9c9c;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #ff4d4d;
    text-align: center;
    margin-top: 15px;
  }