// SCSS for SuccessModal
.success-modal {
    .modal-content {
      color: #092475 !important; // Set the text color for the modal
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); // Add box shadow
      border: none; // Remove border for a cleaner look
      border-radius: 8px; // Optional: Add some border radius for rounded corners
  
      .modal-header {
        border-bottom: none; // Remove bottom border from the modal header
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .modal-title {
          font-weight: bold;
          font-size: 1.5rem;
          display: flex;
          align-items: center;
  
          // Add an icon before the title
          svg {
            margin-right: 8px;
            color: #092475 !important; // Set icon color
            font-size: 1.6rem; // Adjust iconsize
          }
        }
  
        // Styling for close button (optional)
        .close {
          background: transparent;
          border: none;
          font-size: 1.5rem;
          color: white !important;
          opacity: 0.7;
          transition: opacity 0.3s;
  
          &:hover {
            opacity: 1;
          }
  
          span {
            font-size: 2rem;
          }
        }
      }
  
      .modal-body {
        padding: 20px;
        font-size: 1rem;
      }
  
      .modal-footer {
        border-top: none; // Remove the top border from modal footer
        display: flex;
        justify-content: flex-end;
  
        .btn-primary {
          background-color: #092475 !important;
          border: none;
          color: #fff !important;
          padding: 8px 16px;
          border-radius: 4px;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: darken(#092475, 10%);
          }
        }
      }
    }
  
    // Fade-in animation for the modal (optional)
    &.fade {
      .modal-content {
        opacity: 0;
        transform: translateY(-20px);
        transition: all 0.4s ease;
  
        &.show {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
  