.program-schema-form-container {
  padding: 20px;

  h1 {
    font-size: 31px;
    font-weight: bold;
    color: #333;
  }

  .d-flex {
    justify-content: space-between;
    align-items: center;
  }

  button {
    &.btn-primary {
      background-color: #007bff;
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }

    &.btn-secondary {
      background-color: #6c757d;
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #565e64;
      }
    }
  }

  // Offcanvas Sidebar Styling
  .offcanvas {
    width: 350px;
    background-color: white; // Set background color to white
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    color: white !important;
 

    .offcanvas-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      color: white !important;
    }

    form {
      .form-label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color: white !important; // Adjust the label color
      }

      .form-control {
        height: 38px;
        padding: 8px;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #ced4da;
        box-shadow: none;
        color: #092475; // Set input text color to #092475

        &:focus {
          border-color: #80bdff;
          outline: none;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }

      .mb-3 {
        margin-bottom: 1.5rem;
      }

      .z-btn {
        background-color: #28a745;
        color: #fff;
        border: none;
        font-size: 16px;
        padding: 10px 20px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #218838;
        }
      }
    }
  }


/* Custom ReactQuill Editor Styling */
.custom-react-quill {
  .ql-container {
    height: 300px; /* Sets height of the editor */
    font-size: 16px; /* Adjusts font size for readability */
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px;
    background-color: #ffffff;

    .ql-editor {
      min-height: 200px; /* Ensures the editor has a minimum height */
    }

    .ql-toolbar {
      background-color: #f1f3f5;
      border-bottom: 1px solid #ced4da;
    }
  }

  /* Focus state for better UX */
  .ql-container.ql-snow.ql-focused {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

  table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
    


    th,
    td {
      padding: 12px 15px;
      text-align: left;
      border: 1px solid white;
    }

    th {
      background-color: #092475;
      color: white;
      font-weight: bold;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
      
    }

    tr:hover {
      background-color: #ddd;
    }

    img {
      max-width: 100px;
      border-radius: 4px;
      object-fit: cover;
    }

    .text-center {
      text-align: center;
    }
  }
}
.button-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}