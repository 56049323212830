/*----------------------------------------*/
/*  06. COUNTER CSS START
/*----------------------------------------*/

.counter{
    &__area{
        position: relative;
        &-2{
            &::before{
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 330%;
                background: url('../img/bg/wave-bg.png');
                z-index: -1;
            }
        }
    }
    &__inner{
        @include border-radius(20px);
        @include box-shadow(0px 40px 80px 0px rgba(43, 2, 89, 0.1));
        padding: 90px 0;
        padding-bottom: 65px;
    }
    &__item{
        & h2{
            font-size: 50px;
            margin-bottom: 0;
            font-weight: 900;
            line-height: 1;
        }
        & span{
            text-transform: capitalize;
        }
        &-2{
            & span{
                color: $black-soft-2;
            }
        }
    }
}
.counter__area {
    padding-top: 85px;
    padding-bottom: 85px;
}

.container {
    padding: 0 15px; // Adjust container padding as needed
}

.heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.heading-section h1 {
    font-size: 40px; /* Adjust this value to make it a little bigger */
}

.row .col-md-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    margin-bottom: 10px; // Space between icon and counter
}

// Media queries for small screens
@media (max-width: 600px) {
    .heading-section {
        padding: 15px;

        h1 {
            font-size: 20px;
            margin: 0 0 8px;
        }

        .z-btn {
            padding: 8px 16px;
            font-size: 14px;
            width: 15px;
         
        }
    }
}



