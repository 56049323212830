.swiper-container {
    width: 100%;
 /* Adjust as needed to fit more slides */
    margin: 0 auto;
  }
  
  .slide-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px; /* Space between slides */
  }
  
  .slide-item img {
    width: 250px; /* Fixed width for images */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: Add rounded corners to images */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Add shadow to images */
  }
  .certificate-container {
    text-align: center;
    padding: 20px;
  
    .certificate-heading {
      font-size: 2rem;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .slick-slider {
      margin: 0 auto; // Center slider
    }
  
    .slide-item {
      padding: 10px;
      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }
  
    // Media Queries for responsiveness
    @media (max-width: 1024px) {
      .slide-item img {
        max-width: 90%; // Adjust image size for large screens
      }
    }
  
    @media (max-width: 768px) {
      .certificate-heading {
        font-size: 1.5rem; // Adjust font size for tablets
      }
  
      .slide-item img {
        max-width: 80%;
      }
    }
  
    @media (max-width: 480px) {
      .certificate-heading {
        font-size: 1.2rem; // Smaller font size for mobile
      }
  
      .slide-item img {
        max-width: 100%;
      }
    }
  }
  