.admin-dashboard-container {
  padding: 20px;
  background-color: #f9f9f9; /* Light gray background */


.dashboard-title {
  text-align: center; /* Center the title */
  color: #333; /* Dark gray text color */
  margin-bottom: 20px; /* Space below the title */
  font-size: 28px; /* Large font size */
}

.admin-dashboard-table {
  width: 100%; /* Full width */
  border-collapse: collapse; /* Collapse borders */
  background-color: #fff; /* White background for table */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.admin-dashboard-table th,
.admin-dashboard-table td {
  padding: 12px 15px; /* Padding for cells */
  text-align: center; /* Centered text */
  border-bottom: 1px solid #ddd; /* Light gray border */
}

.admin-dashboard-table th {
  background-color: #4caf50; /* Green header background */
  color: white; /* White text color */
  font-weight: bold; /* Bold font */
}

.agent-row {
  transition: background-color 0.3s ease; /* Smooth transition */
}

.agent-row.active {
  background-color: #eaffea; /* Light green for active agents */
}

.agent-row.inactive {
  background-color: #ffeaea; /* Light red for inactive agents */
}

.toggle-status-btn {
  padding: 8px 12px; /* Padding for buttons */
  background-color: #007bff; /* Primary blue color */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.toggle-status-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.status-badge {
  padding: 5px 10px; /* Padding for badges */
  border-radius: 12px; /* Rounded corners */
  font-size: 0.85rem; /* Slightly smaller font */
  font-weight: bold; /* Bold font */
}

.status-badge.active {
  background-color: #4caf50; /* Green for active badge */
  color: white; /* White text */
}

.status-badge.inactive {
  background-color: #f44336; /* Red for inactive badge */
  color: white; /* White text */
}

.view-details-btn {
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  color: blue; /* Blue text */
  font-size: 20px; /* Larger font size */
  margin-left: 8px; /* Space to the left */

  &:hover {
      color: darkblue; /* Darker blue on hover */
  }
}

/* Modal Styles */
.modal {
  display: flex; /* Flexbox for centering */
  position: fixed; /* Fixed to cover viewport */
  z-index: 1000; /* High z-index for visibility */
  left: 0; /* Align to the left */
  top: 0; /* Align to the top */
  width: 100%; /* Full width */
  height: 100% !important; /* Full height */
  overflow: auto; /* Scroll if needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.modal-content {
  background-color: #fff; /* White background for modal */
  margin: 15px; /* Margin around modal */
  padding: 20px; /* Inner padding */
  border: 1px solid #888; /* Border around modal */
  width: 100%; /* Width of modal */
  max-width: 600px; /* Max width limit */
  height:100% !important;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  position: relative; /* For positioning close button */
}

.close {
  color: #aaa; /* Light gray for close button */
  font-size: 28px; /* Font size for close button */
  font-weight: bold; /* Bold text for visibility */
  cursor: pointer; /* Pointer cursor on hover */
  position: absolute; /* Absolute positioning */
  top: 10px; /* Space from the top */
  right: 20px; /* Space from the left */

  &:hover,
  &:focus {
      color: black; /* Change color on hover */
      text-decoration: none; /* Remove underline */
      cursor: pointer; /* Pointer cursor */
  }
}
}