.live-currency {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .chart-container {
      width: 100%;
      height: 400px;
    }
  }
  