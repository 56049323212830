.otp-dialog {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the modal content horizontally */
  justify-content: center; /* Center the modal content vertically */
  padding: 1rem; /* Add padding around the modal content */

  .otp-header {
    position: relative;
    padding: 1rem 1.5rem;
    background-color: #f5f5f5; // Header background color
    border-bottom: 1px solid #ddd; // Header border

    h3 {
      margin: 0;
    }
  }

  .otp-inputs {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;

    input {
      width: 50px; /* Default width for desktop */
      height: 50px; /* Default height for desktop */
      font-size: 1.5rem; /* Default font size for desktop */
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  }

  .verify-button-container {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    margin-top: 20px; /* Adjust as needed */
  }

  .verify-button {
    display: inline-block;
    height: 54px;
    line-height: 48px;
    border: 2px solid #092475;
    background: #092475;
    color: #ffffff;
    padding: 0 30px;
    border-radius: 10px;
    font-weight: 700;
    text-transform: capitalize;
    min-width: 150px;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s, color 0.3s, border-color 0.3s;
    cursor: pointer;

    &:hover {
      background: #ffffff;
      color: #092475;
      border-color: #092475;
    }
  }

  .otp-resend {
    text-align: center;
    margin-top: 1rem;

    a {
      color: #007bff; // Link color
      text-decoration: none;

      &:hover {
        text-decoration: underline; // Underline on hover
      }
    }
  }

  @media (max-width: 768px) { /* Tablet view */
    padding: 0.5rem; /* Reduce padding for smaller screens */

    .otp-header {
      padding: 0.5rem 1rem; /* Reduce padding */
      h3 {
        font-size: 1.2rem; /* Smaller font size for headers */
      }
    }

    .otp-inputs {
      margin: 0.5rem 0; /* Reduce margin */
      
      input {
        width: 40px; /* Reduced width for tablet screens */
        height: 40px; /* Reduced height for tablet screens */
        font-size: 1.2rem; /* Smaller font size for tablet screens */
      }
    }

    .verify-button-container {
      margin-top: 10px; /* Reduce margin top */
    }

    .verify-button {
      height: 40px; /* Smaller button height */
      line-height: 36px; /* Adjust line height accordingly */
      padding: 0 20px; /* Reduce padding */
      min-width: 120px; /* Reduce minimum width */
      font-size: 0.9rem; /* Adjust font size for better fit */
    }

    .otp-resend {
      margin-top: 0.5rem; /* Reduce margin top */
      a {
        font-size: 0.9rem; /* Smaller font size for links */
      }
    }
  }

  @media (max-width: 480px) { /* Mobile view */
    .otp-inputs {
      input {
        width: 35px; /* Further reduced width for mobile screens */
        height: 35px; /* Further reduced height for mobile screens */
        font-size: 1rem; /* Smaller font size for mobile screens */
      }
    }

    .verify-button {
      height: 36px; /* Further reduce button height for small screens */
      line-height: 32px; /* Adjust line height accordingly */
      padding: 0 15px; /* Reduce padding for very small screens */
      min-width: 100px; /* Smaller minimum width for small screens */
      font-size: 0.8rem; /* Further adjust font size for better fit */
    }
  }
}

