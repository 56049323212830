.add-programs-container {
  margin: 30px;

  h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
  }

  .add-programs-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .add-programs-row {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .add-programs-col-md-6,
      .add-programs-col-md-12 {
        flex: 1;
        min-width: 300px;
      }

      .mb-3 {
        margin-bottom: 1rem;
      }

      .form-label {
        font-weight: bold;
      }

      // Update border color to blue for form elements
      .form-select,
      .form-control {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #092475; // Blue border color
      }

      .btn-link {
        background: none;
        color: #007bff;
        border: none;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .btn {
      width: 300px;
      border-radius: 5px;
      border: none;
      cursor: pointer;

      &:hover {
       
      border:2px solid #0056b3
      }
    }
  }

  
}

// Keeping existing hover effect for buttons
.add-programs-container .add-programs-form .btn:hover {
  color: #092475 !important;
}

.dropdown-menu.show {
  width: 90%;
  height: 400px;
  overflow-y: scroll;
 }
  /* Custom modal styling */
.custom-modal {
  /* Ensures this modal has its own unique styles */
  .modal-dialog {
    max-width: 80%; /* Makes the modal larger */
    width: 100%;
  }

  .modal-content {
    padding: 20px;
    border-radius: 10px; /* Rounds the corners */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adds shadow for depth */
  }

  .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    padding: 1.25rem;

    .modal-title {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .close {
      font-size: 1.5rem;
    }
  }

  .modal-footer {
    border-top: 1px solid #dee2e6;
    padding: 1rem;
  }

  /* Button customizations */
  .btn-secondary {
    background-color: #6c757d;
    border: none;
    &:hover {
      background-color: #5a6268;
    }
  }

  .btn-primary {
    background-color: #007bff;
    border: none;
    &:hover {
      background-color: #0056b3;
    }
  }
}

/* Custom ReactQuill Editor Styling */
.custom-react-quill {
  .ql-container {
    height: 300px; /* Sets height of the editor */
    font-size: 16px; /* Adjusts font size for readability */
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px;
    background-color: #ffffff;

    .ql-editor {
      min-height: 200px; /* Ensures the editor has a minimum height */
    }

    .ql-toolbar {
      background-color: #f1f3f5;
      border-bottom: 1px solid #ced4da;
    }
  }

  /* Focus state for better UX */
  .ql-container.ql-snow.ql-focused {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}