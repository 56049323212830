.header__btn {
  
    

    &.scrolled-button {
      background-color: #000;
      color: #fff;
    }

 
  }



/* Default styles for all logos */
.desktop-logo {
  display: none; /* Hidden by default */
  height: auto;
  
}

.phn-logo {
  width: 200px; /* Default size for mobile screens */
  height: auto;
  display: block; /* Shown by default for mobile */

 
}

/* For tablets and small laptops (up to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .phn-logo {
    width: 220px; /* Increase size for tablets */
   
  }
}

/* For laptops and larger screens (992px and up) */
@media (min-width: 992px) {
  .desktop-logo {
    display: block; /* Show on larger screens */
    width: 250px; /* Default size for laptops */
    height: auto;
    
   
  }

  .phn-logo {
    display: none; /* Hide on larger screens */
  }
}
.header__bottom-right{
  
  margin: 10px;

}



.desktop-logo {
  max-width: 100%; // Adjust logo size
  margin: 0 auto; // Center within the flex container
  padding: 10px 0; // Optional padding
}

.phn-logo {
  max-width: 100%; // Ensure mobile logo is also responsive
}
