/*----------------------------------------*/
/*  04. FEATURES CSS START
/*----------------------------------------*/

.features{
  
   
    &__inner{
        @include box-shadow(0px 40px 70px 0px rgba(66, 5, 135, 0.3));
        @include border-radius(10px);
        @media #{$xs}{
            margin-top: 100px;
        }
        
    }

    &__item{
        position: relative;
        border-right: 1px solid $border-5;
        z-index: 1;
        min-height: 300px;
       
        

        &:hover{
            & .features__content{
                top: 40%;
            }
            & .features__btn{
                opacity: 0;
                visibility: visible;
                opacity: 1;
            }
            & .features__thumb{
                &::after{
                    opacity: 0;
                }
            }
        }
        &-2{
            position: relative;
            @include border-radius(10px);
            padding: 45px 40px;
            border-right: none;
            @include box-shadow(0px 20px 50px 0px rgba(2, 0, 40, 0.1));
            &:hover{
                & .features__thumb-2{
                    opacity: 1;
                }
                & .features__content-2{
                    & h3{
                        color: $white;
                    }
                    & p{
                        color: $white;
                    }
                }
                & .features__icon{
                    & i{
                        color: $white;
                    }
                }
                & .link-btn{
                    color: $white;
                }
            }
        }
    }
    &__thumb{
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       border-radius: 10px;
       @include background();
       &::after{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            border-radius: 10px;
            background-image: -moz-linear-gradient( 60deg, #c24e4e 0%, #2528cc 100%);
            background-image: -ms-linear-gradient( 60deg,#c24e4e 0%, #2528cc  100%);
            background-image: -webkit-linear-gradient( 60deg,#c24e4e 0%, #2528cc 100%);
            background-image: linear-gradient( 60deg, #c24e4e 0%,#2528cc 100%);
            
            
            
            
          
       }
       &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #0b0385;
            opacity: .8;
            border-radius: 10px;

       }
       &-2{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include background();
            @include transition(.3s);
            border-radius: 10px;
            opacity: 0;
            &::after{
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: -moz-linear-gradient( 60deg, #c24e4e 0%, #2528cc 100%);
                background-image: -ms-linear-gradient( 60deg,#c24e4e 0%, #2528cc  100%);
                background-image: -webkit-linear-gradient( 60deg,#c24e4e 0%, #2528cc 100%);
                background-image: linear-gradient( 60deg, #c24e4e 0%,#2528cc 100%);
                opacity: .8;
                border-radius: 10px;
              
            }
       }
    }

    &__icon{
        margin-bottom: 20px;
        & i{
            font-size: 30px;
            color: $white;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            display: inline-block;
        }
        &-2{
            margin-bottom: 30px;
            & i{
                font-size: 34px;
                color: $theme-color;
            }
        }
    }
    &__content{
        position: absolute;
        top: 55%;
        left: 50%;
        @include transform(translate(-50%,-50%));
        width: 100%;
        @include transition(.3s);
        padding: 0 15px;
        & h3{
            font-size: 20px;
            color: $white;
            margin: 0 auto 15px;
            max-width: 160px;
        }
        
        &-left{
            & p{
                margin-bottom: 65px;
                line-height: 30px
            }
        }
        &-right{
            @media #{$md}{
                margin-top: 50px;
            }
            @media #{$sm}{
                margin-top: 50px;
            }
            @media #{$xs}{
                margin-top: 50px;
            }
        }
        &-2{
            position: relative;
            z-index: 1;
            & h3{
                font-size: 22px;
                font-weight: 900;
            }
            & p{
                margin-bottom: 25px;
            }
        }
    }
    &__btn{
        visibility: hidden;
        opacity: 0;
        @include transition(.3s);
        & a{
            color: $white;
        }
    }
}

.features__area {
    .text-center {
      margin-bottom: 2rem; // Space between the text and features section
      
    }
    box-shadow: none;
  
    h1 {
      font-size: 3.5rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 0.5rem;
      line-height: 1.4;
    }
  
    .p-title{
      font-size: 1.50rem;
      color: #666;
      max-width: 700px;
      margin: 0 auto;
    }
  
    .features__inner {
      padding: 2rem;
    }
  
    .feature__item {
      padding: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-bottom: 1rem;
      text-align: center;
      transition: background-color 0.3s ease, transform 0.3s ease; // Smooth transition
  
      &:hover {
        // background-color: #f0f0f0; // Change background color on hover
        transform: scale(1.05); // Slight zoom effect on hover
      }
  
      .feature__icon {
        img {
          width: 70px; // Adjust size as needed
          height: auto; // Maintain aspect ratio
          max-width: 100%; // Ensure image fits within container
          transition: transform 0.3s ease; // Smooth transition for image
        }
      }
  
      .feature__title {
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 1rem;
        color: #333; // Default text color
        transition: color 0.3s ease; // Smooth transition for color
  
        &:hover {
          color: #007bff; // Change text color on hover
        }
      }
    }
  
    // Ensure proper spacing between rows
    .row {
      margin-bottom: 1rem;
    }
  }
  .features__item{
    border-radius: 10px;
  }


  
