.services__details {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
 
    
    .tabular.menu {
      display: flex;
      justify-content: flex-start;
      border-bottom: 2px solid #092475; // Make sure the border is applied
  
      .item {
        padding: 10px 15px;
        font-weight: bold !important;
        font-size: larger;
        cursor: pointer;
        color: #092475; // Text color for non-active tabs
        background-color: white;
        border-radius: 4px 4px 0 0;
        transition: background-color 0.3s ease, color 0.3s ease;
  
        &:hover {
          background-color: #092475!important; // Use !important to ensure it's applied
          color: white !important;
        }
  
        &.active {
          background-color: #092475 !important; // Ensure active tab has blue background
          color: white !important; // Ensure active tab has white text
          border-bottom: none;
        }
      }
    }
  
    .tab.segment {
      padding: 15px !important;
      background: #f9f9f9 !important;
      border: 2px solid #092475 !important;
      margin-bottom: 20px !important;
      ul {
        padding-left: 20px;
       
        li {
          margin-bottom: 10px !important;
          line-height: 1.6 !important;
          position: relative; // Make sure this is respected
          font-weight: bold !important;
          list-style-type: disc !important; // Custom bullet points for list items
          font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-size:large;
        }
  
        span {
          color: red !important; // Ensure span color applies
          font-weight: bold !important;
        }
      }
    }
  
    h6 {
      margin-bottom: 15px !important;
      font-weight: bold !important;
      line-height: 1.6 !important;
    }
  
    // Responsive styling
    @media screen and (max-width: 768px) {
      .tabular.menu {
        flex-direction: column;
        .item {
          margin-bottom: 10px;
          width: 100%;
          text-align: left;
        }
      }
  
      .tab.segment {
        padding: 10px;
      }
    }
  }
  