.hero {
    &__area {
    }
  
    &__height {
      min-height: 950px;
      @media #{$laptop} {
        min-height: 800px;
      }
      @media #{$md} {
        min-height: 600px;
      }
      @media #{$sm} {
        min-height: 500px;
      }
      @media #{$xs} {
        min-height: 400px;
      }
    }
  
    &__content {
      & span {
        padding: 4px 8px;
        color: $theme-color;
        background: rgba($color: $theme-color, $alpha: 0.1);
        @include border-radius(6px);
        display: inline-block;
        margin-bottom: 10px;
      }
      & h1 {
        font-size: 70px;
        line-height: 1.1;
        margin-bottom: 20px;
        color: $black-4;
        @media #{$xs} {
          font-size: 40px;
        }
      }
      & p {
        font-size: 18px;
        margin-bottom: 55px;
        line-height: 30px;
      }
    }
  
    &__thumb {
      position: absolute; /* Default for large screens */
  
      & img {
        border-radius: 50%; /* Ensure images are circular */
      }
  
      &.one,
      &.two,
      &.three,
      &.four {
        flex: 1 1 calc(50% - 20px); /* 50% width on larger screens */
        max-width: calc(50% - 20px);
      }
  
      /* Show images on large screens */
      @media (min-width: 995px) {
        display: block; /* Make sure images are visible on large screens */
      }
  
      /* Hide all images on screens up to 994px */
      @media (max-width: 994px) {
        display: none; /* Hide all images */
      }
  
      /* Show only one image on screens up to 994px */
      @media (max-width: 994px) {
        &.one {
          display: block;
          top: 0;
          left: 0; /* Adjust as needed for centering */
        }
  
        &.two,
        &.three,
        &.four {
          display: none; /* Hide these images on smaller screens */
        }
      }
  
      /* Positioning for large screens */
      &.one {
        top: 0;
        left: 45px;
        @media #{$laptop} {
          left: 20px; /* Adjust as needed */
        }
        @media #{$md} {
          left: 10px; /* Adjust as needed */
        }
        @media #{$sm} {
          left: 5px; /* Adjust as needed */
        }
      }
  
      &.two {
        left: 295px;
        top: 0;
      }
  
      &.three {
        top: 250px;
        left: 0;
      }
  
      &.four {
        left: 380px;
        top: 320px;
      }
    }
  
    &__shape {
      & img {
        position: absolute;
        &.one {
          top: 34%;
          left: 8%;
          @media #{$laptop} {
            top: 24%;
          }
          @media #{$md} {
            top: 15%;
          }
          @media #{$sm} {
            top: 20%;
          }
          @media #{$xs} {
            top: 20%;
          }
        }
        &.two {
          top: 53%;
          left: 55%;
        }
        &.three {
          top: 20%;
          right: 3%;
        }
        &.four {
          bottom: 6%;
          right: 14%;
        }
        &.five {
          bottom: 3%;
          right: 0;
        }
        &.six {
          left: 3%;
          bottom: 0;
          z-index: 1;
        }
      }
    }
  }
  